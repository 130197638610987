<template>
  <div id="map" style="z-index: 0"></div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { eventBus } from '@/main.js';
import "leaflet-bing-layer";
import axios_services from "@/axios-service.js";
import * as trailData from "@/assets/trails.json";
import TRAIL_COLORS from '@/trail-colors.js'

export default {
  name: "Viewer2D",
  data() {
    return {
      map: null,
      mapConfiguration: {
        center: [46.57, 11.40],
        zoom: 9,
        maxZoom: 18,
      },
      baseMaps: {
        "osm": {
          type: "tileLayer",
          url: "https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png",
          mapObject: null,
          active: false,
          attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        },
        "bing-sat": {
          type: "bing",
          active: true,
          mapObject: null,
          bingKey: "AjU2b-0kG-mPyaBkuT85URKHE9UbksBsK5Yo_EBqW3VkIWI4HhPTWl_Ze24_W4Jn",
          attribution: '&copy; 2021 Microsoft Corporation, &copy; 2021 Maxar, &copy; CNES (2021) Distribution Airbus DS'
        },
        "cycl-osm": {
          type: "tileLayer",
          url: "https://{s}.tile-cyclosm.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png",
          mapObject: null,
          active: false,
          attribution: '<a href="https://github.com/cyclosm/cyclosm-cartocss-style/releases" title="CyclOSM - Open Bicycle render">CyclOSM</a> | Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        },
        "open-topo": {
          type: "tileLayer",
          url: "https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png",
          mapObject: null,
          active: false,
          attribution: 'Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
        }
      },
      dataLayer: null,
      trails: null,
      activeTrailClassification: null
    };
  },
  computed: {
    viwerMode() {
      return this.$store.getters.getViewerMode;
    },
    currentProduct() {
      return this.$store.getters.getCurrentProduct;
    },
    activeSegment() {
      return this.$store.getters.getActiveSegment;
    },
    selectedDate() {
      return this.$store.getters.getSelectedDate;
    }
  },
  mounted() {
    var ref = this;

    eventBus.$on("REFRESH-2D-VIEW", function () {
      ref.refreshView();
    });
    eventBus.$on("SET-BASEMAP", function (mapId) {
      ref.setBaseMap(mapId);
    });
    eventBus.$on("UDPATE_TRAILS", function () {
      ref.loadTrailClassification(ref.selectedDate)
    });
    this.initMap();
    this.loadTrails();
    window.addEventListener('resize', this.foo);
  },
  methods: {
    foo() {
      this.map.invalidateSize();
    },
    /**
     * This function initializes a leaflet map container.
     */
    initMap() {
      this.map = L.map("map").setView(
        this.mapConfiguration.center,
        this.mapConfiguration.zoom
      );

      this.initBaseMaps();

      // Eventhandler for map click event
      var ref = this;
      this.map.on('click', function () {
        ref.setWeightAllFeatures(3);
        ref.$store.commit("setActiveSegment", null);
        this.activeTrailClassification = null;
      });

      this.map.invalidateSize();
    },
    /**
     * This function creates the mouse event handlers for the GeoJSON features.
     */
    onEachFeature(feature, layer) {
      var ref = this;
      layer.on("mouseover", function () {
        this.setStyle({ weight: 6 });
      });
      layer.on("mouseout", function () {
        if ((layer.feature.properties.id != ref.activeSegment)) {
          this.setStyle({ weight: 3 });
        }
      });
      layer.on("click", function (e) {
        this.setStyle({ weight: 6 });
        ref.$store.commit("setActiveSegment", e.target.feature.properties.id);
        //ref.$store.commit("setInformationBarData", "");
        ref.$store.commit("setInformationBar", 1);
        L.DomEvent.stopPropagation(e);
      });
    
      
    },
    /**
     * This function sets the initial style (color, weight) of all features.
     */
    setFeaturesStyleAllFeatures() {
      this.dataLayer.eachLayer(function (layer) {
        if (layer.feature.properties.type == 'MTBtrail') {
          layer.setStyle({ weight: 3, color: "black", dashArray: '4,6'});
        } else {
          layer.setStyle({ weight: 3, color: "black"});
        }
        
      });
    },
    /**
     * This function sets the line weight of all features.
     */
    setWeightAllFeatures(weight) {
      this.dataLayer.eachLayer(function (layer) {
        layer.setStyle({ weight: weight });
      });
    },
    /**
     * This functin initializes the leaflet base maps.
     */
    initBaseMaps() {
      for (var key in this.baseMaps) {
        if (this.baseMaps[key].type == "tileLayer") {
          this.baseMaps[key].mapObject = L.tileLayer(this.baseMaps[key].url, {
            maxZoom: this.mapConfiguration.maxZoom,
            attribution: this.baseMaps[key].attribution,
          });
        } else if (this.baseMaps[key].type == "bing") {
          this.baseMaps[key].mapObject = L.tileLayer.bing({
            maxZoom: this.mapConfiguration.maxZoom,
            bingMapsKey: this.baseMaps[key].bingKey,
            attribution: this.baseMaps[key].attribution,
          }
          );
        }
        if (this.baseMaps[key].active)
          this.baseMaps[key].mapObject.addTo(this.map);
      }
    },
    /**
     * This function changes the leaflet base layer.
     */
    setBaseMap(mapId) {
      for (var key in this.baseMaps) {
        if (this.baseMaps[key].active) {
          this.map.removeLayer(this.baseMaps[key].mapObject);
          this.baseMaps[key].active = false;
        }
      }
      this.baseMaps[mapId].mapObject.addTo(this.map);
      this.baseMaps[mapId].active = true;
    },
    /**
     * This function refreshes the map and get rid of empty gray areas.
     */
    invalidateMapSize() {
      this.map.invalidateSize();
    },
    /**
     * This function refreshes the leaflet view.
     */
    refreshView() {
      this.map.setView(this.mapConfiguration.center, this.mapConfiguration.zoom);
    },
    /**
     * Loads all trails from the database.
     */
    loadTrails() {
      this.dataLayer = new L.geoJSON(trailData, {
        onEachFeature: this.onEachFeature,
      });

      this.dataLayer.addTo(this.map);
      this.setFeaturesStyleAllFeatures();

      // Eventhandler for map click event
      var ref = this;
      this.map.on('click', function () {
        //ref.setWeightAllFeatures();
        ref.$store.commit("setActiveSegment", null);
      });

    },
    /**
     * This function loads the trail classification data from the backend
     */
    loadTrailClassification(dateStr) {
      // TODO: Change behaviour when user data is available
      this.$store.commit("setDataLoading", true);
      let url = "safetrail/list_trails_info_for_date/".concat(dateStr);
      axios_services.get(url).then((response) => {
        var trailClassification = response.data;
        for (var item in trailClassification) {
          if (trailClassification[item].user_data.class) {
            trailClassification[item].class = trailClassification[item].user_data.class
          }
        }
        this.activeTrailClassification = trailClassification;
        this.$store.commit("setDataLoading", false);
        if (this.$store.getters.getActiveSegment) {
          this.$store.commit("setInformationBarData", this.getInformationBarData(this.$store.getters.getActiveSegment));
        }
        this.applyTrailClassification(trailClassification);
      }).catch(function (error) {
        console.log("Error:", error);
        this.$store.commit("setDataLoading", false);
      });
    },
    /**
     * Applies the color information to the trail segments.
     */
    applyTrailClassification(classificationData) {
      this.dataLayer.eachLayer(function (layer) {
        let segmentID = layer.feature.properties.id;
        var classification = classificationData.find(o => o.trail_id === segmentID);
        if (classification) {
          if (classification.class == 0) {
            layer.setStyle({ color: TRAIL_COLORS.LOW })
          } else if (classification.class === 1) {
            layer.setStyle({ color: TRAIL_COLORS.MIDDLE });
          } else if (classification.class === 2) {
            layer.setStyle({ color: TRAIL_COLORS.HIGH });
          }
        }
      });
    },
    /**
     * This function gets the detailed information for the selected segment. 
     */
    getInformationBarData(id) {
      let classificationData = this.activeTrailClassification.find(o => o.trail_id === id);
      if (classificationData) {
        return classificationData;
      } else {
        return null;
      }
    }
  },
  watch: {
    currentProduct(productValue) {
      console.log("change product to: ", productValue);
    },
    activeSegment(v) {
      if (v == null) {
        this.$store.commit("setInformationBar", 0);
        this.$store.commit("setInformationBarData", null);
      } else {
        this.$store.commit("setInformationBar", 1);
        this.$store.commit("setInformationBarData", this.getInformationBarData(v));
      }
    },
    selectedDate(dateValue) {
      console.log("date changed to: ", dateValue);
      this.setFeaturesStyleAllFeatures();
      this.loadTrailClassification(dateValue)
    }
  },
};
</script>

<style scoped>
#map {
  width: auto;
  height: 100%;
  position: relative;
}
</style>

