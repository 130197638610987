<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500"  @keydown.esc="closeDialog()" @click:outside="closeDialog()">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Risk Details
        </v-card-title>
        <v-card-text style="text-align: justify;">
          <v-data-table hide-default-footer :headers="headers" :items="items" hide-default-header dense></v-data-table>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDialog()">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "RiskLevelDialog",
  components: {},
  data() {
    return {
      headers: [
        {
          text: 'Name',
          align: '',
          sortable: false,
          value: 'level2Key',
        },
        {
          text: 'Value',
          align: '',
          sortable: false,
          value: 'level2Value',
        },
      ]
    };
  },
  computed: {
    dialog() {
      return this.$store.getters.getRiskLevelDialog;
    },
    items() {
      var items = [];
      if (this.$store.getters.getRiskLevelDialogData) {
        for (const [key, value] of Object.entries(this.$store.getters.getRiskLevelDialogData)) {
          console.log(key)
          let k = key;
          let v = value;

          if (["class", "edit_timestamp"].includes(k)) {
            console.log("hallo")
            let handleKey = {
              'class' : { key: "Risk Level", f: this.getLevelName },
              'edit_timestamp' : { key: "Edit Timestamp", f: this.getFormatedTimestamp }
            };
            v = handleKey[k].f(v);
          } else {
            v = value;
          }
          if (k == "class") {
            k = "risk_level";
          }
        
          items.push({ "level2Key": this.getNameUpperCase(k), "level2Value": v });
        }
      }
      return items;
    }
  },
  mounted() {
  },
  methods: {
    closeDialog() {
      this.$store.commit("setRiskLevelDialogData", null);
      this.$store.commit("setRiskLevelDialog", false);
    },
    getNameUpperCase(name) {
      var items = name.split('_');
      var itemsUpper = items.map(a => a.charAt(0).toUpperCase() + a.substr(1))
      return itemsUpper.join(' ');
    },
    getLevelName(v) {
      if (v === 0) {
        return "low";
      } else if (v === 1) {
        return "medium";
      } else if (v === 2) {
        return "high";
      } else {
        return v;
      }
    },
    getFormatedTimestamp(v) {
      let d = v.split('.')[0];
      return d.split('T')[0] + " (" + d.split('T')[1] + ")"
    }
  },
};
</script>

<style scoped>

</style>