<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="900" @click:outside="closeDialog()">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          About
        </v-card-title>
        <v-card-text style="text-align: justify;">
          <h4 class="pt-4 mb-2">SafeTRAIL</h4>
          <p>
            GeoVille GmbH as Prime Contractor and its Sub-contractor Lo.La, from Innsbruck (Austria) have
            responded to the European Space Agency (ESA) Invitation to Tender (ITT) “Prototype methods to
            assess the impact of changing tourism trends on sensitive alpine ecosystems” in July 2021.
          </p>

          <p>
            This project is part of the ESA EO4ALPS Regional Initiative and has a regional focus on the impact of
            alpine tourism. Each of the Regional Initiatives aims at improving the use of EO data to address key
            scientific and societal challenges and needs. More specifically, rather than only providing EO datasets
            (e.g. on a server), the related projects should also demonstrate data uptake by relevant “insitu-networks
            and local knowledge”.
          </p>

          <p>
            To comply with the latter “requirement”, the team of this project has not only federated a critical
            mass of stakeholders from an institutional level, but also stakeholders at a local level from three
            selected transboundary regions of the Tyrol-South Tyrol-Trentino Euroregion, namely Gschnitztal,
            Ötztal-Schnalstal and Sella Ronda-Dolomites. Only by knowing local needs, innovative EO products
            with potential for operationalization can be developed so that product uptake in the future is
            assured.
          </p>

          <p>
            Based on this local input and expertise, information on various topics is compiled, created and
            evaluated in a fashion to provide users, maintainers and promoters of the alpine trail system with
            relevant and up-to-date information on conditions and safety concerns.
          </p>

        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDialog()">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
export default {
  name: "AboutDialog",
  components: {},
  data() {
    return {
    };
  },
  computed: {
    dialog() {
      return this.$store.getters.getAboutDialog;
    }
  },
  methods: {
    closeDialog() {
      this.$store.commit("setAboutDialog", false);
    }
  },
};
</script>

<style scoped>

</style>