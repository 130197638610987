<template>
  <div>
    <v-navigation-drawer app width="22%" permanent id="nav2" style="z-index: 1; left: 56px" v-show="informationBar != 0">
      <v-list-item id="header">
        <v-list-item-content>
          <v-list-item-title>
            <h4>Risks & Metadata</h4>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <div v-if="!noDataAvailable">
        <v-row>
          <v-col class="ml-2 mr-2 mt-2 mb-0">
            <div v-if="trailType == 'hike'">
              <v-icon class="mr-2">mdi-hiking</v-icon><span class="overline">Hiking Trail</span>
            </div>
            <div v-else-if="trailType == 'bike'">
              <v-icon class="mr-2">mdi-bike</v-icon><span class="overline">Mountainbike Trail</span>
            </div>
            <!-- <div><v-icon class="mr-2">mdi-bike</v-icon><span class="overline">Bike Trail</span></div> -->
          </v-col>
        </v-row>
        <v-alert class="mt-2 ml-2 mr-2" dense :type="risk['type']" :color="risk['color']">
          Risk Level: <strong>{{risk['text']}}</strong>
          <v-row class="text-right" v-if="userData.class != null || userLoggedIn">
            <v-col v-if="userData != null">
              <v-btn class="mt-2" small block outlined tile @click="showRiskLevelDetails()">
                Details ...
              </v-btn>
            </v-col>
            <v-col v-if="userLoggedIn">
              <v-btn class="mt-2" small block outlined tile @click="editDialog()">
                Edit
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>


        <v-card class="ml-2 mr-2 mt-2">
          <v-card-title>
            Risks
          </v-card-title>
          <v-data-table hide-default-header hide-default-footer :headers="risksHeaders" :items="risksData"
            class="elevation-0 mt-0">
            <template v-slot:[`item.riskClass`]="{ item }">
              <div v-if="item.riskValue === 'temperature'">
                <v-chip class="chip-width" v-if="item.riskClass==0" close-icon="mdi-close-outline" color="green" label dark
                  small>{{getTemperatureText(item.riskDetails.temperature.max_temperature_of_day)}}</v-chip>
                <v-chip class="chip-width" v-else-if="item.riskClass==1" close-icon="mdi-close-outline" color="orange" label
                  dark small >{{getTemperatureText(item.riskDetails.temperature.max_temperature_of_day)}}</v-chip>
                <v-chip class="chip-width" v-else-if="item.riskClass==2" close-icon="mdi-close-outline" color="red" label
                  dark small >{{getTemperatureText(item.riskDetails.temperature.max_temperature_of_day)}}</v-chip>
              </div>
              <div v-else>
                <v-chip class="chip-width" v-if="item.riskClass==0" close-icon="mdi-close-outline" color="green" label dark
                  small>{{getLevelNames(item)}}</v-chip>
                <v-chip class="chip-width" v-else-if="item.riskClass==1" close-icon="mdi-close-outline" color="orange" label
                  dark small >{{getLevelNames(item)}}</v-chip>
                <v-chip class="chip-width" v-else-if="item.riskClass==2" close-icon="mdi-close-outline" color="red" label
                  dark small >{{getLevelNames(item)}}</v-chip>
              </div>
            </template>
            <template v-slot:[`item.riskDetails`]="{item}">
              <a v-if="item.riskDetails!=null" target="_blank" @click="showDetails()">
                Details
              </a>
              <div v-else>&#8211;</div>
            </template>
            <template v-slot:[`item.actions`]="{item}">
              <v-icon @click="showDetails(item)">
                mdi-information
              </v-icon>
            </template>
          </v-data-table>
        </v-card>

        <v-card class="ml-2 mr-2 mt-2">
          <v-card-title>
            Basic Information
          </v-card-title>
          <v-data-table hide-default-header hide-default-footer :headers="basicInformationHeaders" :items="basicInformationData"
            class="elevation-0 mt-0">
            <template v-slot:[`item.riskClass`]="{ item }">
              <v-chip class="chip-width" v-if="item.riskClass==0" close-icon="mdi-close-outline" color="green" label dark
                small>{{getLevelNames(item)}}</v-chip>
              <v-chip class="chip-width" v-else-if="item.riskClass==1" close-icon="mdi-close-outline" color="orange" label
                dark small >{{getLevelNames(item)}}</v-chip>
              <v-chip class="chip-width" v-else-if="item.riskClass==2" close-icon="mdi-close-outline" color="red" label
                dark small >{{getLevelNames(item)}}</v-chip>
            </template>
            <template v-slot:[`item.riskDetails`]="{item}">
              <a v-if="item.riskDetails!=null" target="_blank" @click="showDetails()">
                Details
              </a>
              <div v-else>&#8211;</div>
            </template>
            <template v-slot:[`item.actions`]="{item}">
              <v-icon
                v-if="(item.riskValue != 'protected_area') || (item.riskValue == 'protected_area' && item.riskClass == 1) || (item.riskValue == 'protected_area' && item.riskClass == 2)"
                @click="showDetails(item)">
                mdi-information
              </v-icon>
            </template>
          </v-data-table>
        </v-card>

        <v-card class="ml-2 mr-2 mt-3 mb-2">
          <v-card-title class="pb-1">
            Trail Details
          </v-card-title>
          <v-data-table hide-default-footer :headers="topologicalDataHeader" :items="topologicalData" hide-default-header dense>
          </v-data-table>
        </v-card>
      </div>
      <div v-else>
        <v-container>
          <v-alert color="orange" dense prominent type="warning">No data available for this segment</v-alert>
        </v-container>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import * as trailData from "@/assets/trails.json";

export default {
  name: "InformationBar",
  components: {},
  data() {
    return {
      noDataAvailable: true,
      trailType: null,
      alertLevel: null,
      userData: null,
      risk: {},
      risksHeaders: [
        {
          text: 'Risk Name',
          align: '',
          sortable: false,
          value: 'riskName',
        },
        {
          text: 'Risk Class',
          align: '',
          sortable: false,
          value: 'riskClass'
        },
        {
          text: 'Actions',
          align: '',
          sortable: false,
          value: 'actions'
        }
      ],
      risksData: [],
      basicInformationData: [],
      basicInformationHeaders: [
        {
          text: 'Risk Name',
          align: '',
          sortable: false,
          value: 'riskName',
        },
        {
          text: 'Risk Class',
          align: '',
          sortable: false,
          value: 'riskClass'
        },
        {
          text: 'Actions',
          align: '',
          sortable: false,
          value: 'actions'
        }
      ],
      topologicalDataHeader: [
        {
          text: 'Name',
          align: '',
          sortable: false,
          value: 'level2Key',
        },
        {
          text: 'Value',
          align: '',
          sortable: false,
          value: 'level2Value',
        },
      ],
      topologicalData: [],
    };
  },
  computed: {
    informationBar() {
      return this.$store.getters.getInformationBar;
    },
    activeSegment() {
      return this.$store.getters.getActiveSegment;
    },
    informationBarData() {
      return this.$store.getters.getInformationBarData;
    },
    userLoggedIn() {
      return this.$store.getters.getUserLoggedIn;
    },
  },
  mounted() {
  },
  methods: {
    showDetails(item) {
      this.$store.commit("setLevel2InformationDialog", true);
      this.$store.commit("setLevel2InformationDialogData", {items: item.riskDetails[item.riskValue], name: item.riskValue});
    },
    showRiskLevelDetails() {
      this.$store.commit("setRiskLevelDialogData", this.userData);
      this.$store.commit("setRiskLevelDialog", true);
    },
    setRisksTableAndBasicInformationData(data) {
      this.risksData = [];
      this.basicInformationData = [];

      for (const [key, value] of Object.entries(data.level2_data)) {
        if (["trail_classification", "protected_area"].includes(key)) {
          this.basicInformationData.push({ 
            riskName: this.getNameUpperCase(key),
            riskValue: key,
            riskClass: value.class,
            riskDetails: data.level2_data // Could be filtered
          });
        } else {
          this.risksData.push({ 
            riskName: this.getNameUpperCase(key),
            riskValue: key,
            riskClass: value.class,
            riskDetails: data.level2_data // Could be filtered
          });
        }
      }
    },
    setUserData(data) {
      if (data.user_data == null) {
        this.userDataAvailable = false;
        this.userData = null;
      } else {
        this.userDataAvailable = true;
        this.userData = data.user_data;
      }
    },
    setRisk(data) {
      this.risk = this.getAlertTypeAndColor(data.class);
    },
    setTopologicalData(data) {
      this.topologicalData = [];
      for (const [key, value] of Object.entries(data.topological_data)) {
        this.topologicalData.push({ "level2Key": this.getNameUpperCase(key), "level2Value": value });
      }
    },
    setAlertLevel(data) {
      if (data.user_data == null) {
        this.alertLevel = data.class;
      } else {
        this.alertLevel = data.user_data.class;
      }
    },
    setTrailType(data) {
      let trail = trailData.features.find(o => o.properties.id === data.trail_id);
      if (trail.properties.type === 'MTBtrail') {
        this.trailType = 'bike';
      } else if (trail.properties.type === 'Hikingtrail') {
        this.trailType = 'hike';
      }
    },
    getNameUpperCase(name) {
      var items = name.split('_');
      var itemsUpper = items.map(a => a.charAt(0).toUpperCase() + a.substr(1))
      return itemsUpper.join(' ');
    },
    getAlertTypeAndColor(classValue) {
      if (classValue == 0) {
        return { type: "success", color: "green", text: "Low" }
      } else if (classValue == 1) {
        return { type: "error", color: "orange", text: "Medium" }
      } else if (classValue == 2) {
        return { type: "error", color: "red", text: "High" }
      }
    },
    editDialog() {
      this.$store.commit("setEditDialog", true);
    },
    getLevelNames(item) {
      let classNames = {
        precipitation: { 0: "Light", 1: "Heavy", 2: "Extreme" },
        protected_area: { 0: "No", 1: "Yes", 2: "Yes" },  // kein info button wenn "No"
        snow_occurance: { 0: "Low", 1: "Medium", 2: "High" },
        temperature: { 0: "Comfortable", 1: "Cold / Hot", 2: "Very cold / hot" },
        trail_classification: { 0: "Flat", 1: "Moderate", 2: "Steep" },
        trail_condition: { 0: "Dry", 1: "Moist", 2: "Wet" },
        wind: { 0: "Calm", 1: "Moderate", 2: "Strong / Storm" },
      };
      return classNames[item.riskValue][item.riskClass];
    },
    getTemperatureText(v) {
      if (v < 0) {
        return "Very cold";
      } else if (v < 12) {
        return "Cold";
      } else if ((v >= 12) && (v < 25)) {
        return "Comfortable";
      } else if ((v >= 25) && (v < 30)) {
        return "Hot"
      } else if (v >= 30) {
        return "Very hot"
      }
    }
  },
  watch: {
    informationBarData(data) {
      if (data) {
        this.noDataAvailable = false;
        this.setAlertLevel(data)
        this.setUserData(data);
        this.setRisksTableAndBasicInformationData(data);
        this.setTopologicalData(data);
        this.setRisk(data);
        this.setTrailType(data);
        this.setTrailType(data);
      } else {
        this.noDataAvailable = true;
      }
    }
  }
};
</script>

<style scoped>
#header {
  height: 56px;
}

.chip-width {
  width: 120px;
  justify-content: center !important;
}

</style>
