<template>
  <div>
    <v-card id="menu" width="280px" class="container" style="background-color: rgba(255, 255, 255, 0.8)">
      <div class="logos">
        <v-row>
          <v-col cols="6">
            <a href="https://www.geoville.com/" target="_blank">
              <v-img src="Logo_GeoVille_150px.png" width="100%" />
            </a>
          </v-col>
          <v-col cols="6">
            <a href="https://www.lo-la.info/" target="_blank">
              <v-img src="Logo_Lola_150px.png" width="100%" />
            </a>
          </v-col>
        </v-row>
      </div>
      <v-expansion-panels multiple>
        <v-expansion-panel class="layer-panel">
          <v-expansion-panel-header>
            <div class="header-label">Base Layer</div>
          </v-expansion-panel-header>
          <v-expansion-panel-content fluid>
            <v-row style="margin: 0px">
              <v-col v-for="(element, id) in baseMaps" :key="id" style="max-width: fit-content; padding: 5px">
                <v-img @click="setBaseMap(id)" :src="element.icon" class="basemap-icon" :style="[element.active
                    ? { border: '3px solid #1e90b6' }
                    : { border: '3px solid #DDDDDD' },
                ]" />
              </v-col>
            </v-row>
            <v-card id="map-attribution" flat>
              <div v-html="mapAttribution"></div>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-card class="mt-2 container">
        <div class="ml-0">
          <v-dialog ref="dialog" v-model="modal" :return-value.sync="date" persistent width="280px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="date" label="Date" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on">
              </v-text-field>
            </template>
            <v-date-picker v-model="date" scrollable :allowed-dates="allowedDates">
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal = false">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="foo(date)">
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </div>
      </v-card>
    </v-card>
  </div>
</template>

<script>
import { eventBus } from '@/main.js';

export default {
  name: "ProductWidget",
  components: {},
  mounted() {
    this.setBaseMapAttribution("bing-sat");
    this.initDate();
  },
  data() {
    return {
      baseMaps: {
        "osm": {
          attribution:'&copy; <a href="http://www.openstreetmap.org/copyright" target="blank">OpenStreetMap</a> Contributors<br/>Projection: EPSG 3857',
          icon: "osm-logo.png",
          active: false
        },
        "bing-sat": {
          attribution:"&copy; 2021 Microsoft Corporation, &copy; 2021 Maxar, &copy; CNES (2021) Distribution Airbus DS<br/>Projection: EPSG 3857",
          icon: "bing-logo.png",
          active: true
        },
        "cycl-osm": {
          attribution:'&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> Contributors, <br/>&copy; <a href="https://github.com/cyclosm/cyclosm-cartocss-style/releases" title="CyclOSM - Open Bicycle render">CyclOSM</a><br/>Projection: EPSG 3857',
          icon: "cyclosm-logo2.png",
          active: false
        },
        "open-topo": {
          attribution:'&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> Contributors, <br/>&copy; <a href="https://opentopomap.org">OpenTopoMap</a></a><br/>Projection: EPSG 3857',
          icon: "opentopo-logo.png",
          active: false
        }
      },
      mapAttribution: "",
      date: null,
      modal: false,
      availableDates: ["2022-04-01", "2022-04-02","2022-04-03","2022-04-04","2022-04-05","2022-04-06","2022-04-07","2022-04-08","2022-04-09","2022-04-10",
      "2022-04-11","2022-04-12","2022-04-13","2022-04-14","2022-04-15","2022-04-16","2022-04-17","2022-04-18","2022-04-19","2022-04-20","2022-04-21",
      "2022-04-22","2022-04-23","2022-04-24","2022-04-25","2022-04-26","2022-04-27","2022-04-28","2022-04-29","2022-04-30",
      "2022-05-01", "2022-05-02","2022-05-03","2022-05-04","2022-05-05","2022-05-06","2022-05-07","2022-05-08","2022-05-09","2022-05-10",
      "2022-05-11","2022-05-12","2022-05-13","2022-05-14","2022-05-15","2022-05-16","2022-05-17","2022-05-18","2022-05-19","2022-05-20","2022-05-21",
      "2022-05-22","2022-05-23","2022-05-24","2022-05-25","2022-05-26","2022-05-27","2022-05-28","2022-05-29","2022-05-30","2022-05-31",
      "2022-06-01", "2022-06-02","2022-06-03","2022-06-04","2022-06-05","2022-06-06","2022-06-07","2022-06-08","2022-06-09","2022-06-10",
      "2022-06-11","2022-06-12","2022-06-13","2022-06-14","2022-06-15","2022-06-16","2022-06-17","2022-06-18","2022-06-19","2022-06-20","2022-06-21",
      "2022-06-22","2022-06-23","2022-06-24","2022-06-25","2022-06-26","2022-06-27","2022-06-28","2022-06-29","2022-06-30",]
    };
  },
  methods: {
    initDate() {
      this.date = new Date("2022-04-01").toISOString().substr(0, 10);
      this.$store.commit("setSelectedDate", this.date);
    },
    setBaseMap(mapId) {
      eventBus.$emit("SET-BASEMAP", mapId);
      this.setBaseMapAttribution(mapId);
    },
    setBaseMapAttribution(mapId) {
      this.mapAttribution = this.baseMaps[mapId].attribution;
      for (var key in this.baseMaps) {
        if (this.baseMaps[key].active) {
          this.baseMaps[key].active = false;
        }
      }
      this.baseMaps[mapId].active = true;
      document.getElementById('map').focus();
    },
    foo(date) {
      this.modal = false;
     
      this.$store.commit("setSelectedDate", date);
      this.$nextTick(() => {
        this.date = this.date = new Date(date).toISOString().substr(0, 10);
         document.getElementById('map').focus();
      });
    },
    allowedDates(date) {
      return this.availableDates.includes(date);
    },
  },
  watch: {}
};
</script>

<style scoped>
#menu {
  position: absolute;
  z-index: 1;
  right: 9px;
  top: 9px;
  display: block;
}

.legend {
  text-align: left;
  line-height: 18px;
  color: #555;
  padding: 6px 8px;
  background: white;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  width: 100%;
}

.legend i {
  width: 18px;
  height: 18px;
  float: left;
  margin-right: 8px;
  opacity: 0.7;
}

.legend .colorcircle {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  opacity: 1;
  border: 1px solid;
  border-color: #888888;
}

.legend-padding-header {
  padding-bottom: 8px;
}

.legend-padding-items {
  padding-bottom: 2px;
}

.basemap-icon {
  border-radius: 50% !important;
  display: inline-block;
  width: 42px;
  height: 42px;
  background: white;
}

.basemap-icon:hover {
  cursor: pointer;
}
</style>

<style>
#map-attribution {
  width: 100%;
  padding: 5px;
  margin-bottom: 5px;
  font-size: 11.5px !important;
}

#product-selector .v-input__control {
  caret-color: rgba(0, 0, 0, 0) !important;
  background-color: #ffffff;
}

.logos {
  padding: 5px;
  border-radius: 5px;
  text-align: -webkit-center;
}

.header-label {
  font-size: 18px;
}


</style>
