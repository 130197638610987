<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500" @click:outside="closeDialog()">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Edit
        </v-card-title>
        <v-card-text style="text-align: justify;">
          <v-row style="padding-top: 5px;">
            <v-col md="auto"><v-chip @click="changeColor(0)" :color="color[0]">Low</v-chip></v-col>
            <v-col md="auto"><v-chip @click="changeColor(1)" :color="color[1]">Medium</v-chip></v-col>
            <v-col md="auto"><v-chip @click="changeColor(2)" :color="color[2]">High</v-chip></v-col>
          </v-row>
          <v-text-field
            v-model="text"
            label="Comment"
            required
          ></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" :disabled="disabled" text @click="saveDialog()">
            Save
          </v-btn>
          <v-btn color="primary" text @click="closeDialog()">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import { eventBus } from '@/main.js';
import axios_services from "@/axios-service.js";

export default {
  name: "EditDialog",
  components: {},
  data() {
    return {
      valid: false,
      color: ['grey','grey','grey'],
      riskActive: -1,
      colorActive: ['green', 'orange', 'red'],
      text: null,
    };
  },
  computed: {
    dialog() {
      return this.$store.getters.getEditDialog;
    },
    disabled() {
      if (this.text) {
        return this.riskActive < 0;
      }
      return true;
    },
    informationBarData() {
      return this.$store.getters.getInformationBarData;
    },
  },
  methods: {
    closeDialog() {
      this.$store.commit("setEditDialog", false);
    },
    saveDialog() {
      var item = this.informationBarData;
      var data = {"classification_id": item.classification_id,
                  "user": "hüttenwirt@tirol.at",
                  "comment": this.text,
                  "classification": this.riskActive}

                  axios_services.post("safetrail/update_trail", data).then(() => {
        console.log("Send Event")
        eventBus.$emit('UDPATE_TRAILS');
        this.closeDialog();
      }).catch(error => {
        console.log("Error:", error);
      });
    },
    changeColor(index) {
      var color = ['grey','grey','grey'];
      var risk = -1;
      if(this.color[index] === 'grey') {
        color[index] = this.colorActive[index];
        risk = index;
      }
      this.color = color;
      this.riskActive = risk;
    },

  },
};
</script>

<style scoped>
</style>