<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500" @keydown.esc="closeDialog()" @click:outside="closeDialog()">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{data}}
        </v-card-title>
        <v-card-text style="text-align: justify;">
          
          <v-data-table hide-default-footer :headers="headers" :items="items" hide-default-header dense></v-data-table>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDialog()">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "Level2InformationDialog",
  components: {},
  data() {
    return {
      headers: [
        {
          text: 'Name',
          align: '',
          sortable: false,
          value: 'level2Key',
        },
        {
          text: 'Value',
          align: '',
          sortable: false,
          value: 'level2Value',
        },
      ]
    };
  },
  computed: {
    dialog() {
      return this.$store.getters.getLevel2InformationDialog;
    },
    data() {
      var name = ""
      if (this.$store.getters.getLevel2InformationDialogData) {
        name = this.getNameUpperCase(this.$store.getters.getLevel2InformationDialogData["name"]);
      }
      return name;
    },
    items() {
      var items = [];
      if (this.$store.getters.getLevel2InformationDialogData) {
        for (const [key, value] of Object.entries(this.$store.getters.getLevel2InformationDialogData["items"])) {
          let k = key;
          let v = value;


          if ((k == 'class') && (this.$store.getters.getLevel2InformationDialogData["name"] == 'protected_area'))  {
            k = "Protected Area";
            v = this.getProtectedAreaName(v);
          }

          if (k === 'class') {
            k = "Risk Level";
            v = this.getLevelName(v);
          } 
          
          items.push({ "level2Key": this.getNameUpperCase(k), "level2Value": v });
        }
      }
      return items;
    }
  },
  methods: {
    closeDialog() {
      this.$store.commit("setLevel2InformationDialog", false);
      this.$store.commit("setLevel2InformationDialogData", null);
    },
    getNameUpperCase(name) {
      var items = name.split('_');
      var itemsUpper = items.map(a => a.charAt(0).toUpperCase() + a.substr(1))
      return itemsUpper.join(' ');
    },
    getLevelName(v) {
      if (v === 0) {
        return "low";
      } else if (v === 1) {
        return "medium";
      } else if (v === 2) {
        return "high";
      } else {
        return v;
      }
    },
    getProtectedAreaName(v) {
      if (v === 0) {
        return "No";
      } else if (v === 1) {
        return "Yes";
      } else if (v === 2) {
        return "Yes";
      } else {
        return v;
      }
    }
  },
};
</script>

<style scoped>

</style>