import Vue from 'vue'
import App from './App.vue'
import "@/auth";
import vuetify from './plugins/vuetify'
import store from "./store/store.js";

Vue.config.productionTip = false

// Eventbus
export const eventBus = new Vue();

Vue.$keycloak
  .init({
  })
  .success((auth) => {
    if (auth) {
      console.log("Authentication successful");
      let payload = {
        accessToken: Vue.$keycloak.token,
        refreshToken: Vue.$keycloak.refreshToken,
      };

      store.commit("setUserLoggedIn",true);
      // Store the access and refresh token
      localStorage.setItem('accessToken', payload.accessToken);
      localStorage.setItem('refreshToken', payload.refreshToken)
            
      // Create the vue ap
      // Token refresh
      setInterval(() => {
        Vue.$keycloak.updateToken(70).then((refreshed) => {
          if (refreshed) {
            
            let payload = {
              accessToken: Vue.$keycloak.token,
              refreshToken: Vue.$keycloak.refreshToken,
            };

            // Uodate the access and refresh token
            localStorage.setItem('accessToken', payload.accessToken);
            localStorage.setItem('refreshToken', payload.refreshToken)
          } else {
            //console.log('Token not refreshed, valid for '  + Math.round(Vue.$keycloak.tokenParsed.exp + Vue.$keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
          }
        }).catch(()=>{
            console.log('Error: Failed to refresh token');
        });
      }, 6000);
    }
  })
  .error(() => {
    console.log("Error: Authenticated failed");
  });

  new Vue({
    vuetify,
    store,
    render: h => h(App),
  }).$mount('#app')



