<template>
  <v-app>
    <v-main>

      <v-overlay :value="dataLoading" z-index="3">
        <div style="text-align: center;">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
          <!-- <p class="pt-4 overline">Loading Data ... {{dataLoading}}</p> -->
        </div> 
      </v-overlay>

      <AboutDialog />
      <StartupDialog />
      <EditDialog />
      <Level2InformationDialog />
      <RiskLevelDialog />
      <NavigationDrawer/>
      <InformationBar/>

      <div id="map-container" v-if="viewerMode === '2d'">
        <Viewer2D v-bind:style="mapStyle" ref="map"/>
        <ProductWidget />
      </div>
      <div id="cesium-container" v-else-if="viewerMode === '3d'" v-bind:style="mapStyle">
        <!-- <Viewer3D/> -->
      </div>

    </v-main>
  </v-app>
</template>

<script>
import NavigationDrawer from "./components/NavigationDrawer";
import ProductWidget from "./components/ProductWidget.vue";
import Viewer2D from "./components/Viewer2D.vue";
// import Viewer3D from "./components/Viewer3D.vue";
import InformationBar from "./components/InformationBar.vue"
import AboutDialog from "./components/AboutDialog.vue"
import StartupDialog from "./components/StartupDialog.vue"
import Level2InformationDialog from "./components/Level2InformationDialog.vue"
import RiskLevelDialog from "./components/RiskLevelDialog.vue"
import EditDialog from "./components/EditDialog.vue";

export default {
  name: "App",
  components: {
    AboutDialog,
    StartupDialog,
    Level2InformationDialog,
    RiskLevelDialog,
    NavigationDrawer,
    ProductWidget,
    Viewer2D,
    // Viewer3D,
    InformationBar,
    EditDialog
},
  computed: {
    viewerMode() {
      return this.$store.getters.getViewerMode;
    },
    informationBar() {
      return this.$store.getters.getInformationBar;
    },
    dataLoading() {
      return this.$store.getters.getDataLoading;
    }
  },
  mounted() {
    this.$store.commit("setViewerMode", "2d");
    this.$store.commit("setStartupDialog", true);
  },
  data: () => ({
    viewer: true,
    items: ["Raw data", "Product A", "Product B", "Product C"],
    xx: "Raw data",
    mapStyle: "",
  }),
  watch: {
    informationBar(newValue, oldValue) {
      if (newValue == 0) {
        this.mapStyle = ""
        setTimeout(this.$refs.map.invalidateMapSize, 10);
      } else if (oldValue == 0 || newValue == 0) {
        this.mapStyle = "left: calc(22% + 11px); width: calc(78% - 11px);";
        if (this.bounds) {
          setTimeout(this.$refs.map.updateBounds, 10);
        }
      }
    },
  },
};
</script>

<style>
html {
  overflow-y: hidden;
}

::-webkit-scrollbar {
  width: 14px;
}

::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: #AAAAAA;
}
</style>

<style scoped>
  #map-container {
    width: 100%;
    height: 100%;
  }
  
  #cesium-container {
    position: absolute;
    width: 100%;
    height: 100%;
  }
</style>