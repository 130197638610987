import Vue from "vue";
import Vuex from "vuex";
import "../auth.js";

Vue.use(Vuex);

/* eslint-disable */
const store = new Vuex.Store({
  state: {
    dataLoading: !true,
    userLoggedIn: false,
    viewerMode: "",
    baseMaps: {},
    informationBar: 0,
    currentProduct: null,
    activeSegment: null,
    aboutDialog: false,
    startupDialog: false,
    level2InformationDialog: false,
    level2InformationDialogData: null,
    riskLevelDialog: false,
    riskLevelDialogData: null,
    editDialog: false,
    selectedDate: null,
    informationBarData: null,
  },
  mutations: {
    setDataLoading(state, loadingState) {
      state.dataLoading = loadingState;
    },
    setUserLoggedIn(state, userLoggedIn) {
      state.userLoggedIn = userLoggedIn;
    },
    setViewerMode(state, mode) {
      state.viewerMode = mode;
    },
    setBaseMaps(state, payload) {
      state.baseMaps = payload;
    },
    setCurrentProduct(state, payload) {
      state.currentProduct = payload;
    },
    setInformationBar(state, mode) {
      state.informationBar = mode;
    },
    setActiveSegment(state, segmentId) {
      state.activeSegment = segmentId;
    },
    setAboutDialog(state, payload) {
      state.aboutDialog = payload;
    },
    setStartupDialog(state, payload) {
      state.startupDialog = payload;
    },
    setLevel2InformationDialog(state, payload) {
      state.level2InformationDialog = payload;
    },
    setLevel2InformationDialogData(state, payload) {
      state.level2InformationDialogData = payload;
    },
    setRiskLevelDialog(state, payload) {
      state.riskLevelDialog = payload;
    },
    setRiskLevelDialogData(state, payload) {
      state.riskLevelDialogData = payload;
    },
    setSelectedDate(state, payload) {
      state.selectedDate = payload;
    },
    setInformationBarData(state, payload) {
      state.informationBarData = payload;
    },
    setEditDialog(state, payload) {
      state.editDialog = payload;
    },
  },
  actions: {
    login({commit, dispatch}) {
      Vue.$keycloak
        .init({
          onLoad: "login-required",
        })
        .success((auth) => {
          if (!auth) {
            window.location.reload();
            dispatch("login");
          } else {
            console.log("Authentication successful");
            let payload = {
              accessToken: Vue.$keycloak.token,
              refreshToken: Vue.$keycloak.refreshToken,
            };
            commit("setUserLoggedIn",true);

            // console.log("payload: ", payload);

            // Store the access and refresh token
            localStorage.setItem('accessToken', payload.accessToken);
            localStorage.setItem('refreshToken', payload.refreshToken)

            // Create the vue app
            

            // Token refresh
            setInterval(() => {
              Vue.$keycloak.updateToken(70).then((refreshed) => {
                if (refreshed) {
                  // console.log('Token refreshed');

                  let payload = {
                    accessToken: Vue.$keycloak.token,
                    refreshToken: Vue.$keycloak.refreshToken,
                  };

                  // Uodate the access and refresh token
                  localStorage.setItem('accessToken', payload.accessToken);
                  localStorage.setItem('refreshToken', payload.refreshToken)
                } else {
                  //console.log('Token not refreshed, valid for '  + Math.round(Vue.$keycloak.tokenParsed.exp + Vue.$keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
                }
              }).catch(() => {
                console.log('Error: Failed to refresh token');
              });
            }, 6000);
          }
        })
        .error(() => {
          console.log("Authenticated failed");
        });
    },
    logout({commit}) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      commit("setUserLoggedIn",false);
      Vue.$keycloak.logout({});
    }
  },
  getters: {
    getDataLoading(state) {
      return state.dataLoading;
    },
    getUserLoggedIn(state) {
      return state.userLoggedIn;
    },
    getViewerMode(state) {
      return state.viewerMode;
    },
    getBaseMaps(state) {
      return state.baseMaps;
    },
    getCurrentProduct(state) {
      return state.currentProduct;
    },
    getInformationBar(state) {
      return state.informationBar;
    },
    getActiveSegment(state) {
      return state.activeSegment;
    },
    getAboutDialog(state) {
      return state.aboutDialog;
    },
    getStartupDialog(state) {
      return state.startupDialog;
    },
    getLevel2InformationDialog(state) {
      return state.level2InformationDialog;
    },
    getLevel2InformationDialogData(state) {
      return state.level2InformationDialogData;
    },
    getRiskLevelDialog(state) {
      return state.riskLevelDialog;
    },
    getRiskLevelDialogData(state) {
      return state.riskLevelDialogData;
    },
    getSelectedDate(state) {
      return state.selectedDate;
    },
    getInformationBarData(state) {
      return state.informationBarData;
    },
    getEditDialog(state) {
      return state.editDialog;
    },
  },
});

/* eslint-enable */
export default store;
