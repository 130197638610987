<template>
  <div>
    <v-navigation-drawer app left v-model="drawer" :mini-variant="mini" permanent >
      <v-list-item link>
        <v-list-item-icon v-if="userLoggedIn" @click="logout()">
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-icon v-else-if="!userLoggedIn" @click="login()">
          <v-icon>mdi-login</v-icon>
        </v-list-item-icon>
        <v-list-item-title>User section</v-list-item-title>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item link :disabled="activeSegment==null">
        <v-list-item-icon @click="toggleInformationBar()">
          <v-icon :disabled="activeSegment==null">mdi-traffic-light</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Trail information</v-list-item-title>
      </v-list-item>
      <!-- <v-list-item link>
        <v-list-item-icon v-if="viewerMode == '2d'" @click="toggleViewerMode()">
          <v-icon>mdi-video-3d</v-icon>
        </v-list-item-icon>
        <v-list-item-icon v-else-if="viewerMode == '3d'" @click="toggleViewerMode()">
          <v-icon>mdi-video-2d</v-icon>
        </v-list-item-icon>
        <v-list-item-title>2D / 3D viewer</v-list-item-title>
      </v-list-item> -->
      <v-list-item link>
        <v-list-item-icon @click="refresh()">
          <v-icon>mdi-refresh</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Refresh</v-list-item-title>
      </v-list-item>
      <v-list-item link>
        <v-list-item-icon  @click="showAbout()">
          <v-icon>mdi-information-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>About</v-list-item-title>
      </v-list-item>
      <v-list-item link href="https://www.geoville.com/footer/imprint/" target="_blank" class="fixedBottom">
        <v-list-item-icon >
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">mdi-format-section</v-icon>
            </template>
            <span>Imprint</span>
          </v-tooltip>
        </v-list-item-icon>
        <v-list-item-title>Imprint</v-list-item-title>
      </v-list-item>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { eventBus } from '@/main.js';

export default {
  name: "NavigationDrawer",
  components: {},
  computed: {
    informationBarVisibility() {
      return this.$store.getters.getInformationBar;
    },
    userLoggedIn() {
      return this.$store.getters.getUserLoggedIn;
    },
    activeSegment() {
      return this.$store.getters.getActiveSegment;
    }
  },
  data() {
    return {
      drawer: true,
      mini: true,
      viewerMode: ""
    };
  },
  mounted() {
    this.viewerMode = "2d";
    this.drawer2 = 0;
  },
  methods: {
    login() {
      this.$store.dispatch("login");
    },
    logout() {
      this.$store.dispatch("logout");
    },
    toggleInformationBar() {
      let currentVisibility = this.informationBarVisibility;
      this.$store.commit("setInformationBar", !currentVisibility);
    },
    toggleViewerMode() {
      if (this.viewerMode === "2d") {
        this.viewerMode = "3d";
        this.$store.commit("setViewerMode", "3d");
      } else if (this.viewerMode === "3d") {
        this.viewerMode = "2d";
        this.$store.commit("setViewerMode", "2d");
      }
    },
    refresh() {
      if (this.viewerMode === "2d") { 
        eventBus.$emit("REFRESH-2D-VIEW");
      }
    },
    showAbout() {
      this.$store.commit("setAboutDialog", true);
      document.getElementById('map').focus(); // Note: his is used to get rid of the gray background issue after the click event
    }
  },
  watch: {
    informationBarVisibility(v) {
      this.$store.commit("setInformationBar", v);
    },
  },
};
</script>

<style scoped>
.v-navigation-drawer {
  z-index: 100 !important;
}
.fixedBottom {
  position: fixed !important;
  bottom: 0 !important;
  width: 100%;
}
</style>
 