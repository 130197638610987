import { render, staticRenderFns } from "./Viewer2D.vue?vue&type=template&id=144a65bd&scoped=true"
import script from "./Viewer2D.vue?vue&type=script&lang=js"
export * from "./Viewer2D.vue?vue&type=script&lang=js"
import style0 from "./Viewer2D.vue?vue&type=style&index=0&id=144a65bd&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "144a65bd",
  null
  
)

export default component.exports