<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="600" @click:outside="closeDialog()">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          SafeTRAIL
        </v-card-title>
        <v-card-text style="text-align: justify;" class="pt-4">

          <p class="font-weight-bold">
            Prototype – Do NOT use in an Alpine environment!
          </p>
          <p>
            The demonstrator is a prototype product in the framework of the said ESA project and subject to
            continuous development and improvement.
          </p>
          <p>
            The access to this demonstrator is not open to the general public. The contractor does not
            guarantee correctness of the provided information and cannot be held responsible for the contents
            and fatalities resulting from them.
          </p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDialog()">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
export default {
  name: "StartupDialog",
  components: {},
  data() {
    return {
    };
  },
  computed: {
    dialog() {
      return this.$store.getters.getStartupDialog;
    }
  },
  methods: {
    closeDialog() {
      this.$store.commit("setStartupDialog", false);
    }
  },
};
</script>

<style scoped>

</style>